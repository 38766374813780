<template>
  <div class="rankings text-white p-2 flex flex-col">
    <!-- Selector -->
    <div v-if="userIsDirector" class="selector">
      <div
        class="selector-item"
        :class="{ selected: currentFilter === 'director' }"
        @click="filter('director')"
      >
        DIRECTOR
      </div>
      <div
        class="selector-item"
        @click="filter('employee')"
        :class="{ selected: currentFilter === 'employee' }"
      >
        GENERAL
      </div>
    </div>
    <!-- Date Picker -->
    <div v-if="datesSet" class="flex items-center justify-between gap-4 w-full text-black mb-4">
      <div class="input-container">
        <label for="dateTo">Fecha</label>
        <div class="flex gap-4 items-center">
          <input v-model="dateTo" type="date" name="dateTo" @change="fetchData" />
          <div class="info-icon" @click="visibilizeInfo('add')">
            <span>i</span>
            <div ref="info" class="info">
              <p>
                Los reportes mensuales se calculan desde el primer día del mes de la fecha
                seleccionada
              </p>
              <p>
                Los reportes anuales se calculan desde el primer día del año de la fecha
                seleccionada
              </p>
            </div>
            <div
              ref="infoBackdrop"
              class="info-backdrop"
              @click.stop="visibilizeInfo('remove')"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading">
      <!-- Reports -->
      <div class="reports flex flex-wrap justify-center gap-2">
        <!-- Report -->
        <div v-for="(report, r) in reports" :key="r" class="report bg-black px-4 py-4">
          <div>
            <div class="mb-8">
              <h1 class="text-xl bg-black pr-12">{{ metadata[r].title }}</h1>
              <div v-if="metadata[r].hasBonus" class="tag">BONUS DIFICULTAD</div>
            </div>
            <!-- Row -->
            <div v-for="(item, i) in report" :key="item.name" class="row mb-2 relative">
              <div class="row px-4 py-0.5">
                <div
                  class="percentage-bar absolute h-full top-0 left-0"
                  :style="{ backgroundColor: item.color, width: getWidth(r, item) }"
                ></div>
                <div class="content flex justify-between bg-transparent relative">
                  <div class="left">
                    <span class="item-number inline-block">{{ i + 1 }}.</span>
                    <span>{{ item.name }}</span>
                  </div>
                  <div>{{ item.value }} {{ metadata[r].unit }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Prizes -->
      <div v-if="currentFilter === 'employee'" class="prizes p-2 mt-4 text-black">
        <div class="prize-item mb-4">
          <div class="img"><img src="../assets/01_mensual@3x.png" alt="" /></div>
          <div>
            <div>Premio del mes:</div>
            <div>Cena para 2.</div>
          </div>
        </div>
        <div class="prize-item">
          <div class="img"><img src="../assets/02_anual@3x.png" alt="" /></div>
          <div>
            <div>Premio Anual:</div>
            <div>Fin de semana en <br> Porta hotel antigua.</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading">
      <div class="mb-32">Cargando reportes</div>
    </div>
  </div>
</template>

<script>
import { isDirector } from '@/utils/roles'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'Rankings',
  data() {
    return {
      loading: true,
      userIsDirector: false,
      reports: [],
      metadata: [],
      currentFilter: 'employee',
      // dateFrom: '',
      dateTo: '',
      datesSet: false,
      directorReports: [],
      directorMetadata: [
        { unit: '', maxVal: 0, title: 'Ranking trabajo por cliente por mes' },
        { unit: '', maxVal: 0, title: 'Ranking trabajo por cliente total' },
        { unit: 'pts', hasBonus: true, maxVal: 0, title: 'Ranking trabajo por cliente por mes' },
        { unit: 'pts', hasBonus: true, maxVal: 0, title: 'Ranking trabajo por cliente total' },
        { unit: '%', maxVal: 0, title: 'Ranking retrabajos por cliente por mes' },
        { unit: '%', maxVal: 0, title: 'Ranking retrabajos por cliente total' },
        {
          unit: '',
          maxVal: 0,
          title: 'Ranking trabajo categorizado en admin por mes'
        },
        {
          unit: '',
          maxVal: 0,
          title: 'Ranking trabajo categorizado en admin total'
        },
        {
          unit: 'pts',
          maxVal: 0,
          hasBonus: true,
          title: 'Ranking trabajo categorizado en admin por mes'
        },
        {
          unit: 'pts',
          maxVal: 0,
          hasBonus: true,
          title: 'Ranking trabajo categorizado en admin total'
        }
      ],
      employeeReports: [],
      employeeMetadata: [
        { unit: '%', maxVal: 0, title: 'Ranking percepción de desempeño del mes' },
        { unit: '%', maxVal: 0, title: 'Ranking percepción de desempeño total' },
        { unit: '', maxVal: 0, title: 'Ranking trabajos realizados en el mes' },
        { unit: '', maxVal: 0, title: 'Ranking trabajos realizados total' },
        { unit: 'pts', maxVal: 0, hasBonus: true, title: 'Ranking trabajos realizados en el mes' },
        { unit: 'pts', maxVal: 0, hasBonus: true, title: 'Ranking trabajos realizados total' },
        { unit: '', maxVal: 0, title: 'Ranking de trabajos ingresados del mes' },
        { unit: '', maxVal: 0, title: 'Ranking de trabajos ingresados total' }
      ]
    }
  },

  computed: {
    ...mapGetters({ user: 'user/user' })
  },

  async created() {
    this.userIsDirector = isDirector(this.user.roles)

    // const aMonthAgo = new Date()
    // aMonthAgo.setMonth(aMonthAgo.getMonth() - 1)
    // this.dateFrom = this.buildDateString(aMonthAgo)

    this.dateTo = this.buildDateString(new Date())
    this.datesSet = true

    this.fetchData()
  },

  beforeDestroy() {
    this.setActiveLayout('MainLayout')
  },
  methods: {
    ...mapActions({
      getForEmployee: 'reports/getGeneralMonthlyReport',
      getForDirector: 'reports/getDirectorMonthlyReport'
    }),
    ...mapMutations({ setActiveLayout: 'layout/setActiveLayout' }),

    visibilizeInfo(classListMethod) {
      console.log(classListMethod)
      this.$refs.info.classList[classListMethod]('visible')
      this.$refs.infoBackdrop.classList[classListMethod]('visible')
    },

    async fetchData() {
      console.log(this.dateTo)
      if (this.userIsDirector) {
        this.fetchDirectorReports()
      }
      await this.fetchEmployeeReports()

      this.filter(this.currentFilter)
    },

    async fetchEmployeeReports() {
      this.employeeReports = []
      this.employeeMetadata.forEach((data) => {
        data.maxVal = 0
      })

      this.loading = true
      try {
        const { data, success, message } = await this.getForEmployee({
          // from: this.dateFrom,
          to: this.dateTo
        })
        if (success) {
          this.employeeReports = data.map((report) => report.data)
          console.log(this.employeeReports)
          this.setMetadataMaxValues(this.employeeReports, this.employeeMetadata)
        } else {
          this.handleError(message)
        }
      } catch (error) {
        this.handleError(error)
      } finally {
        this.loading = false
      }
    },

    async fetchDirectorReports() {
      this.directorReports = []
      this.directorMetadata.forEach((data) => {
        data.maxVal = 0
      })

      this.loading = true
      try {
        const { data, success, message } = await this.getForDirector({
          // from: this.dateFrom,
          to: this.dateTo
        })
        if (success) {
          this.directorReports = data.map((report) => report.data)
          // console.log(this.directorReports)
          this.setMetadataMaxValues(this.directorReports, this.directorMetadata)
        } else {
          this.handleError(message)
        }
      } catch (error) {
        this.handleError(error)
      } finally {
        this.loading = false
      }
    },

    setMetadataMaxValues(reports, metadata) {
      for (var i = 0; i < reports.length; i++) {
        var max = 0
        reports[i].forEach((row) => {
          if (row.value > max) max = row.value
        })
        metadata[i].maxVal = max
      }
    },

    filter(userType) {
      if (userType === 'employee') {
        this.reports = this.employeeReports
        this.metadata = this.employeeMetadata
      } else {
        this.reports = this.directorReports
        this.metadata = this.directorMetadata
      }
      this.currentFilter = userType
    },

    buildDateString(date) {
      let month = (date.getMonth() + 1).toString()
      if (month.length === 1) month = '0' + month
      let day = date.getDate().toString()
      if (day.length === 1) day = '0' + day
      const year = date.getFullYear().toString()

      return `${year}-${month}-${day}`
    },

    getWidth(reportIndex, item) {
      const metadata = this.metadata[reportIndex]
      var percentage = 0
      if (metadata.unit === '%') {
        percentage = item.value
      } else {
        percentage = (item.value / metadata.maxVal) * 100
      }
      // calibration
      if (percentage < 3) percentage = 3
      if (percentage > 97) percentage = 97
      return percentage + '%'
    },

    handleError(error) {
      console.log('error', error)
      alert('error: ' + error)
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
}
.rankings {
  min-height: 100vh;
  // min-height: calc(100vh - 59px - 1.5rem);
}
.selector {
  @apply flex justify-center gap-4 w-full mb-4;
}
.input-container {
  @apply mb-1;
  // width: 48%;
}
.input-container label {
  @apply text-white block mb-2;
  font-family: WestmountBold;
}
input[type='date'] {
  width: 100%;
}
input[type='date']:focus-visible {
  outline: none;
}
.info-icon {
  border: 1px solid rgb(177, 177, 177);
  color: white;
  border-radius: 50%;
  width: 26px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  position: relative;
}
.info {
  position: absolute;
  top: 100%;
  margin-top: 0.5rem;
  background: white;
  color: #333;
  z-index: 2;
  padding: 0.5rem 1rem;
  min-width: 250px;
  border-radius: 10px;
}
.info p {
  margin-bottom: 0.5rem;
}
.info:not(.visible) {
  display: none;
}
.info-icon:hover .info {
  display: unset;
}

.info-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 1;
}

.info-backdrop:not(.visible) {
  display: none;
}

.selector-item {
  @apply px-3 py-2 text-center select-none;
  width: 50%;
  background-color: #333;
  transition: border-color 145ms ease-in;
  color: #888;
  border-left: 2px solid rgba(242, 178, 52, 0);
  &.selected {
    color: white;
    border-color: rgba(242, 178, 52, 1);
  }
}
.reports {
  font-family: WestmountBold;
  letter-spacing: unset;
}
.report {
  width: 100%;
  max-width: 340px;
  overflow: hidden;
}
.item-number {
  min-width: 32px;
}
.tag {
  font-family: ProximaNovaBlack;
  font-size: 18px;
  letter-spacing: 5px;
  background: rgb(242, 178, 52);
  padding-top: 2px;
  margin-top: 0.25rem;
  color: black;
  text-align: center;
  width: 120%;
  -webkit-transform: rotate(-4deg) translateX(-10%);
  transform: rotate(-4deg) translateX(-10%);
}
.prizes {
  background: -webkit-linear-gradient(45deg, #fbdb73, #c99344);
  background: linear-gradient(90deg, #fbdb73, #c99344);
  font-family: WestmountExtraBold;
  font-size: 18px;
  max-width: 500px;
  margin: 0.5rem auto 0.5rem;
}
.prize-item {
  @apply flex items-center gap-2;
  line-height: 1;
  letter-spacing: -2px;
  .img {
    border-radius: 5px;
    background: transparent;
    width: 86px;
    img {
      width: 100%;
      max-width: unset;
    }
  }
}
</style>